import React from 'react';
import {
  Alert,
  AlertVariant,
  Icon,
  IconName,
} from '@estimateone/frontend-components';
import { ERROR_TEXT } from './constants';
import styles from './FormError.module.scss';

export const FormError = () => (
  <Alert className={styles.serverError} variant={AlertVariant.Red}>
    <Icon className={styles.serverErrorIcon} name={IconName.Clear} />
    <p>{ERROR_TEXT}</p>
  </Alert>
);
