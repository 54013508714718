import React from 'react';
import { InputFieldWrapper } from 'modules/FinalFormFields/InputFieldWrapper';
import { useTranslator } from '../../providers/TranslationsProvider';
import { ProfileOnboardingFieldNames } from 'components/types';

export const ABN = () => {
  const translate = useTranslator();

  return (
    <div>
      <InputFieldWrapper<string>
        field={ProfileOnboardingFieldNames.CompanyABN}
        id={ProfileOnboardingFieldNames.CompanyABN}
        hideLabel
        label={translate('businessIdentifier')}
        placeholder={translate('businessIdentifier')}
        validator={(identifier?: string) =>
          !identifier
            ? [translate('missingBusinessIdentifierMessage')]
            : undefined
        }
      />
    </div>
  );
};
