import React from 'react';
import { E1Link } from '@estimateone/frontend-components';
import { useLanguage } from '@src/providers/TranslationsProvider/TranslationsProvider';

export const FindYourABNLink = () => {
  const language = useLanguage();

  if (language !== 'en_AU') {
    return <></>;
  }

  return (
    <>
      <span> Can't find your ABN? </span>
      <E1Link
        target="_blank"
        rel="noreferrer"
        href="https://abr.business.gov.au/"
      >
        Look up ABN
      </E1Link>
    </>
  );
};
