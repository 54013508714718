import { gql } from '@apollo/client';

export const GET_USER_CONTEXT = gql`
  query UserContextQuery {
    currentUser {
      ...UserDetails
    }
  }

  fragment UserDetails on User {
    id
    isAdmin
    account {
      id
    }
  }
`;
