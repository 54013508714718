import React from 'react';
import { useForm } from 'react-final-form';
import { Fieldset, FieldWrapper } from '@estimateone/frontend-components';
import { SubmissionErrors, ValidationErrors } from 'final-form';
import { SingleSelectDropdownField } from 'modules/FinalFormFields/SingleSelectDropdownField';
import { contractSizeLabelMap } from './utils';
import {
  ProfileOnboardingFieldLabels,
  ProfileOnboardingFieldNames,
} from 'components/types';
import {
  ProfileContractSizeMaxEnum as ContractSizeMaxEnum,
  ProfileContractSizeMinEnum as ContractSizeMinEnum,
} from 'types/graphql.types';
import styles from './ContractSize.module.scss';

export const ContractSizeMinEnumMap: Record<ContractSizeMinEnum, number> = {
  [ContractSizeMinEnum.Size_10k]: 10000,
  [ContractSizeMinEnum.Size_50k]: 50000,
  [ContractSizeMinEnum.Size_100k]: 100000,
  [ContractSizeMinEnum.Size_250k]: 250000,
  [ContractSizeMinEnum.Size_500k]: 500000,
  [ContractSizeMinEnum.Size_1M]: 1000000,
  [ContractSizeMinEnum.Size_5M]: 5000000,
};

export const ContractSizeMaxEnumMap: Record<ContractSizeMaxEnum, number> = {
  [ContractSizeMaxEnum.Size_10k]: 10000,
  [ContractSizeMaxEnum.Size_50k]: 50000,
  [ContractSizeMaxEnum.Size_100k]: 100000,
  [ContractSizeMaxEnum.Size_250k]: 250000,
  [ContractSizeMaxEnum.Size_500k]: 500000,
  [ContractSizeMaxEnum.Size_1M]: 1000000,
  [ContractSizeMaxEnum.Size_5M]: 5000000,
  [ContractSizeMaxEnum.Size_10MPlus]: 10000000,
};

export type MinContractSizeOption = {
  value: ContractSizeMinEnum | null;
  label: string;
};
export type MaxContractSizeOption = {
  value: ContractSizeMaxEnum | null;
  label: string;
};
export const EMPTY_CONTRACT_SIZE = {
  value: null,
  label: '-',
};

export function enumToContractSizeInputOption<
  T extends ContractSizeMinEnum | ContractSizeMaxEnum,
>(value: T): { value: T; label: string } {
  return {
    value: value,
    label: contractSizeLabelMap(value),
  };
}

const minContractSizeOption = Object.keys(ContractSizeMinEnum)
  .map((value: unknown) => value as keyof typeof ContractSizeMinEnum)
  .map((value) => ContractSizeMinEnum[value])
  .map(enumToContractSizeInputOption)
  .sort(
    (a, b) => ContractSizeMinEnumMap[a.value] - ContractSizeMinEnumMap[b.value],
  );

const maxContractSizeOption = Object.keys(ContractSizeMaxEnum)
  .map((value: unknown) => value as keyof typeof ContractSizeMaxEnum)
  .map((value) => ContractSizeMaxEnum[value])
  .map(enumToContractSizeInputOption)
  .sort(
    (a, b) => ContractSizeMaxEnumMap[a.value] - ContractSizeMaxEnumMap[b.value],
  );

type ContractSizeProps = {
  submitErrors: SubmissionErrors;
  errors: ValidationErrors;
};

export const ContractSize = ({ submitErrors, errors }: ContractSizeProps) => {
  const { getState } = useForm();
  const { modified, submitFailed } = getState();

  const shouldShowErrors =
    modified?.[ProfileOnboardingFieldNames.MinContractSize] ||
    modified?.[ProfileOnboardingFieldNames.MaxContractSize] ||
    submitFailed;

  return (
    <div>
      <Fieldset legend="Contract Size" hideLegend showChildErrorStyles>
        <FieldWrapper
          errors={
            errors?.[ProfileOnboardingFieldNames.MinAndMaxContractSize] ||
            submitErrors?.[ProfileOnboardingFieldNames.MinAndMaxContractSize]
          }
          hideErrorMessages={!shouldShowErrors}
        >
          <div className={styles.adjacentFields}>
            <div>
              <div className={styles.customLabel}>
                {ProfileOnboardingFieldLabels.MinContractSize}
              </div>
              <SingleSelectDropdownField<MinContractSizeOption>
                id="min-contract-size"
                label={ProfileOnboardingFieldLabels.MinContractSize}
                hideLabel
                fieldName={ProfileOnboardingFieldNames.MinContractSize}
                options={[EMPTY_CONTRACT_SIZE, ...minContractSizeOption]}
              />
            </div>
            <div>
              <div className={styles.customLabel}>
                {ProfileOnboardingFieldLabels.MaxContractSize}
              </div>
              <SingleSelectDropdownField<MaxContractSizeOption>
                id="max-contract-size"
                label={ProfileOnboardingFieldLabels.MaxContractSize}
                hideLabel
                fieldName={ProfileOnboardingFieldNames.MaxContractSize}
                options={[EMPTY_CONTRACT_SIZE, ...maxContractSizeOption]}
              />
            </div>
          </div>
        </FieldWrapper>
      </Fieldset>
    </div>
  );
};
