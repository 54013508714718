const en_AU = {
  language: 'en_AU',
  messages: {
    formDescription:
      'An up-to-date Company Profile is the key to connecting with new builders and getting access to best job opportunities on EstimateOne.',
    businessIdentifier: 'ABN',
    businessIdentifierFieldHeader: 'What is your company ABN?',
    businessIdentifierFieldDescription:
      'Adding your ABN shows builders that you are a legitimate subcontractor whom they can trust.',
    projectCategoryFieldDescription:
      'Be found by builders tendering projects that you specialise in.',
    missingBusinessIdentifierMessage: 'Please enter an ABN',
  },
};

const en_IE = {
  language: 'en_IE',
  messages: {
    formDescription:
      'An up-to-date Company Profile is the key to connecting with new main contractors and getting access to best job opportunities on EstimateOne.',
    businessIdentifier: 'CRN',
    businessIdentifierFieldHeader: 'What is your company CRN?',
    businessIdentifierFieldDescription:
      'Adding your CRN shows main contractors that you are a legitimate subcontractor whom they can trust.',
    projectCategoryFieldDescription:
      'Be found by main contractors tendering projects that you specialise in.',
    missingBusinessIdentifierMessage: 'Please enter a CRN',
  },
};

const en_GB = {
  language: 'en_GB',
  messages: {
    formDescription:
      'An up-to-date Company Profile is the key to connecting with new main contractors and getting access to best job opportunities on EstimateOne.',
    businessIdentifier: 'CRN',
    businessIdentifierFieldHeader: 'What is your company CRN?',
    businessIdentifierFieldDescription:
      'Adding your CRN shows main contractors that you are a legitimate subcontractor whom they can trust.',
    projectCategoryFieldDescription:
      'Be found by main contractors tendering projects that you specialise in.',
    missingBusinessIdentifierMessage: 'Please enter a CRN',
  },
};

export const localTranslations = [en_IE, en_AU, en_GB];
