import React from 'react';
import { SkeletonLoader } from '@estimateone/frontend-components';
import styles from './ProfileOnboarding.module.scss';

const FieldSkeletonLoader = () => (
  <div>
    <SkeletonLoader height="16px" width="25%" />
    <SkeletonLoader
      height="12px"
      width="75%"
      className={styles.skeletonDescription}
    />
    <SkeletonLoader height="40px" width="100%" />
  </div>
);

export const FormSkeletonLoader = () => (
  <div className={styles.formFieldsWrapper}>
    <FieldSkeletonLoader />
    <FieldSkeletonLoader />
    <div>
      <div className={styles.skeletonAdjacentFieldsHeader}>
        <SkeletonLoader height="16px" width="25%" />
        <SkeletonLoader height="12px" width="100%" />
      </div>
      <div className={styles.skeletonAdjacentFieldsWrapper}>
        <div>
          <SkeletonLoader
            height="16px"
            width="30%"
            className={styles.skeletonDescription}
          />
          <SkeletonLoader height="40px" width="100%" />
        </div>
        <div>
          <SkeletonLoader
            height="16px"
            width="30%"
            className={styles.skeletonDescription}
          />
          <SkeletonLoader height="40px" width="100%" />
        </div>
      </div>
    </div>
    <FieldSkeletonLoader />
  </div>
);
