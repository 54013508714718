import { gql } from '@apollo/client';

const CONTRACT_SIZE_FRAGMENT = gql`
  fragment ContractSize on Profile {
    id
    contractSizeMin
    contractSizeMax
  }
`;

const FIELD_ERRORS_FRAGMENT = gql`
  fragment FieldErrors on FieldErrors {
    errors {
      field
      message
    }
  }
`;

const ERRORS_FRAGMENT = gql`
  fragment Errors on Errors {
    errors {
      field
      message
    }
  }
`;

export const UPDATE_COMPANY_PROFILE = gql`
  ${CONTRACT_SIZE_FRAGMENT}
  ${FIELD_ERRORS_FRAGMENT}
  ${ERRORS_FRAGMENT}
  mutation UpdateCompanyProfile(
    $businessIdentifier: String
    $workforceSize: WorkforceSizeEnum
    $contractSizeMin: ProfileContractSizeMinEnum
    $contractSizeMax: ProfileContractSizeMaxEnum
    $projectCategories: [ProjectCategoryEnum!]!
  ) {
    changeBusinessIdentifier(businessIdentifier: $businessIdentifier) {
      ... on Account {
        id
        businessIdentifier
      }
      ...Errors
    }
    updateCurrentWorkforceSize(workforceSize: $workforceSize)
    updateContractSize(
      contractSizeMin: $contractSizeMin
      contractSizeMax: $contractSizeMax
    ) {
      ...ContractSize
      ...FieldErrors
    }
    updateCurrentProjectCategories(projectCategories: $projectCategories)
  }
`;
