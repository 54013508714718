import React from 'react';
import { MultiSelectDropdownField } from '@src/modules/FinalFormFields/MultiSelectDropdownField';
import { ProfileOnboardingFieldNames } from '@src/components/types';
import { ProjectCategoryEnum } from '@src/types/graphql.types';

const getCategoryLabel = (category: ProjectCategoryEnum | string): string =>
  category
    .replace(/_/g, ' ')
    .replace(
      /(^\w|\s\w)(\S*)/g,
      (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase(),
    );

export const enumToCategoryInputOption = (value: ProjectCategoryEnum) => ({
  value: value,
  label: getCategoryLabel(value),
});

const categoriesOptions = Object.keys(ProjectCategoryEnum)
  .map((value: unknown) => value as keyof typeof ProjectCategoryEnum)
  .map((value: keyof typeof ProjectCategoryEnum) => ProjectCategoryEnum[value])
  .map(enumToCategoryInputOption);

export type CategoryOption = {
  value: ProjectCategoryEnum;
  label: string;
};

export const Categories = () => (
  <div>
    <MultiSelectDropdownField
      id={ProfileOnboardingFieldNames.ProjectCategories}
      label=""
      hideLabel
      fieldName={ProfileOnboardingFieldNames.ProjectCategories}
      placeholder="Project categories (you can select more than one)"
      options={categoriesOptions}
    />
  </div>
);
