import React from 'react';
import { useQuery } from '@apollo/client';
import { UserContext, UserContextProps } from 'context/UserContext';
import { UserContextQuery } from 'modules/UserInfo/__generated__/queries.types';
import { GET_USER_CONTEXT } from 'modules/UserInfo/queries';

const { Provider } = UserContext;

type UserProviderProps = {
  children: React.ReactNode;
};

export const UserProvider = ({ children }: UserProviderProps) => {
  const { loading, data } = useQuery<UserContextQuery>(GET_USER_CONTEXT);

  if (loading || data === undefined) {
    return (
      <Provider value={{ loading: true, context: null }}>{children}</Provider>
    );
  }

  const value: UserContextProps = {
    loading,
    context: {
      user: data.currentUser,
    },
  };

  return <Provider value={value}>{children}</Provider>;
};
