import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
} from 'react';
import { Translator } from 'eo-locale';
import { localTranslations } from './translations';
import { isAscesionWindow } from '@src/types';

const TranslationContext = createContext<Translator | undefined>(undefined);
const isValidLocale = (locale?: string | null) =>
  locale !== null && locale !== undefined && locale.length !== 0;
const isSupportedLocale = (locale: string) =>
  localTranslations.find(({ language }) => language === locale) !== undefined;

const DEFAULT_LOCALE = 'en_AU';

export const TranslationProvider = ({
  children,
}: PropsWithChildren<unknown>) => {
  const locale =
    isAscesionWindow(window) &&
    isValidLocale(window.global.locale) &&
    isSupportedLocale(window.global.locale)
      ? window.global.locale
      : DEFAULT_LOCALE;

  const translator = useMemo(
    () => new Translator(locale, localTranslations),
    [locale],
  );

  return (
    <TranslationContext.Provider value={translator}>
      {children}
    </TranslationContext.Provider>
  );
};

/**
 * Must be used within the `<TranslationProvider />`
 * @example
 * function Foo() {
 *    const translate = useTranslator();
 *
 *    return <p>{translate('businessIdentifier')}</p>;
 * }
 */
export const useTranslator = () => {
  const context = useContext(TranslationContext);

  if (context === undefined) {
    throw new Error(
      'useTranslation must be used within a <TranslationProvider />',
    );
  }

  return context.translate;
};

export const useLanguage = () => {
  const context = useContext(TranslationContext);

  if (context === undefined) {
    throw new Error(
      'useTranslation must be used within a <TranslationProvider />',
    );
  }

  return context.language;
};
