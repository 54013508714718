export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: Date; output: Date };
  EntityId: { input: number; output: number };
};

export type Account = AccountInterface &
  Entity & {
    __typename?: 'Account';
    abbrev?: Maybe<Scalars['String']['output']>;
    /** Unique identifier for this business e.g. ABN */
    businessIdentifier?: Maybe<Scalars['String']['output']>;
    createdAt: Scalars['DateTime']['output'];
    id: Scalars['EntityId']['output'];
    lastActiveAt?: Maybe<Scalars['DateTime']['output']>;
    name: Scalars['String']['output'];
    primaryCountry?: Maybe<Country>;
  };

export type AccountInterface = {
  abbrev?: Maybe<Scalars['String']['output']>;
  businessIdentifier?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type AccountOffice = Entity &
  Office & {
    __typename?: 'AccountOffice';
    address?: Maybe<Address>;
    email?: Maybe<Scalars['String']['output']>;
    id: Scalars['EntityId']['output'];
    name: Scalars['String']['output'];
    phone?: Maybe<Scalars['String']['output']>;
    timezone: Scalars['String']['output'];
    website?: Maybe<Scalars['String']['output']>;
  };

export type AccountOfficeOrErrors = AccountOffice | Errors;

export type AccountOrErrors = Account | Errors;

export type AddOfficeDetailsInput = {
  address: ChangeOfficeDetailsAddressInput;
  email?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type Address = Entity & {
  __typename?: 'Address';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  address3?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country: Country;
  district?: Maybe<Scalars['String']['output']>;
  fullAddress?: Maybe<Scalars['String']['output']>;
  geolocatedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['EntityId']['output'];
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  postcode?: Maybe<Scalars['String']['output']>;
  province?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  shortAddress: Scalars['String']['output'];
  state?: Maybe<State>;
  suburb?: Maybe<Scalars['String']['output']>;
};

export type AddressBook = {
  __typename?: 'AddressBook';
  companyLists: Array<CompanyList>;
  tradeDisciplines: Array<AddressBookTradeDiscipline>;
};

export type AddressBookCompanyResult = {
  __typename?: 'AddressBookCompanyResult';
  address?: Maybe<ProfileSearchAddress>;
  addressBookId: Scalars['ID']['output'];
  archivedAt?: Maybe<Scalars['String']['output']>;
  companyNumber?: Maybe<Scalars['String']['output']>;
  contacts: Array<Contact>;
  createdAt: Scalars['String']['output'];
  createdById?: Maybe<Scalars['ID']['output']>;
  fax?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  linkedAccountId?: Maybe<Scalars['ID']['output']>;
  listIds: Array<Scalars['Int']['output']>;
  location?: Maybe<GeoPoint>;
  name: Scalars['String']['output'];
  offices: Array<ProfileSearchAccountOffice>;
  phone?: Maybe<Scalars['String']['output']>;
  profileId?: Maybe<Scalars['ID']['output']>;
  stockTradeIds: Array<Scalars['Int']['output']>;
  tagIds: Array<Scalars['Int']['output']>;
  taxNumber?: Maybe<Scalars['String']['output']>;
  tradeIds: Array<Scalars['ID']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedById?: Maybe<Scalars['ID']['output']>;
};

export type AddressBookTrade = {
  __typename?: 'AddressBookTrade';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type AddressBookTradeDiscipline = {
  __typename?: 'AddressBookTradeDiscipline';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  trades: Array<AddressBookTrade>;
};

export type ChangeOfficeDetailsAddressInput = {
  address1?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  province?: InputMaybe<Scalars['String']['input']>;
  stateId?: InputMaybe<Scalars['EntityId']['input']>;
  suburb?: InputMaybe<Scalars['String']['input']>;
};

export type ChangeOfficeDetailsInput = {
  accountOfficeId: Scalars['EntityId']['input'];
  address: ChangeOfficeDetailsAddressInput;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyList = {
  __typename?: 'CompanyList';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type CompanyProfileFeatures = {
  __typename?: 'CompanyProfileFeatures';
  /** Indicates if the company profile is capable of accessing advanced features */
  companyProfileAdvanced: Scalars['Boolean']['output'];
  /** Indicates if the company profiles is enabled for the current account */
  companyProfileBasic: Scalars['Boolean']['output'];
};

export type ConnectedProfile = {
  __typename?: 'ConnectedProfile';
  connectedProfile: ConnectedProfileData;
};

export type ConnectedProfileData = ProfileData & {
  __typename?: 'ConnectedProfileData';
  account?: Maybe<UniversalProfileAccount>;
  categories: Array<Scalars['String']['output']>;
  /** ConnectedProfile only fields */
  companyLists: Array<CompanyList>;
  customFields: Array<UniversalProfileCustomField>;
  engagement: EngagementStats;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  offices: Array<UniversalProfileOffice>;
  people: Array<UniversalProfilePerson>;
  source: Source;
  trades: Array<UniversalProfileTrade>;
};

export enum ConnectionType {
  Connected = 'CONNECTED',
  NotConnected = 'NOT_CONNECTED',
}

export type Contact = {
  __typename?: 'Contact';
  email?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export enum ContactType {
  Construction = 'CONSTRUCTION',
  Estimating = 'ESTIMATING',
}

export type Contactable = {
  __typename?: 'Contactable';
  createdAt: Scalars['DateTime']['output'];
  createdById: Scalars['Int']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  deletedById?: Maybe<Scalars['Int']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  facebook?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  linkedIn?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  twitter?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  updatedById: Scalars['Int']['output'];
  web?: Maybe<Scalars['String']['output']>;
};

export enum ContractSizeMaxEnum {
  Size_1M = 'SIZE_1M',
  Size_5M = 'SIZE_5M',
  Size_10MPlus = 'SIZE_10M_PLUS',
  Size_10k = 'SIZE_10k',
  Size_50k = 'SIZE_50k',
  Size_100k = 'SIZE_100k',
  Size_250k = 'SIZE_250k',
  Size_500k = 'SIZE_500k',
}

export enum ContractSizeMinEnum {
  Size_0 = 'SIZE_0',
  Size_1M = 'SIZE_1M',
  Size_5M = 'SIZE_5M',
  Size_10MPlus = 'SIZE_10M_PLUS',
  Size_10k = 'SIZE_10k',
  Size_50k = 'SIZE_50k',
  Size_100k = 'SIZE_100k',
  Size_250k = 'SIZE_250k',
  Size_500k = 'SIZE_500k',
}

export type Country = Entity & {
  __typename?: 'Country';
  currency?: Maybe<Scalars['String']['output']>;
  id: Scalars['EntityId']['output'];
  name: Scalars['String']['output'];
  shortName: Scalars['String']['output'];
  states: Array<State>;
};

export type DailyProfileView = {
  __typename?: 'DailyProfileView';
  accountId: Scalars['EntityId']['output'];
  accountName: Scalars['String']['output'];
  date: Scalars['String']['output'];
  id: Scalars['EntityId']['output'];
};

export type DistanceFromInput = {
  distance: Scalars['Int']['input'];
  location: LocationInput;
};

export type EditIntAccountSettingResponse = IntSetting & {
  __typename?: 'EditIntAccountSettingResponse';
  attribute: Scalars['String']['output'];
  errors: Array<Scalars['String']['output']>;
  id: Scalars['EntityId']['output'];
  success: Scalars['Boolean']['output'];
  value: Scalars['Int']['output'];
};

export type EngagementStats = {
  __typename?: 'EngagementStats';
  awardedPackages: Scalars['Int']['output'];
  companyInvites: Scalars['Int']['output'];
  contactInvites: Scalars['Int']['output'];
  quoted: Scalars['Int']['output'];
  quotedRatePercent: Scalars['Float']['output'];
  responseRatePercent: Scalars['Float']['output'];
};

export type Entity = {
  id: Scalars['EntityId']['output'];
};

export type Error = {
  __typename?: 'Error';
  field?: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
};

/** Type to collect errors */
export type Errors = {
  __typename?: 'Errors';
  errors: Array<Error>;
};

export type Experiment = ExperimentInterface & {
  __typename?: 'Experiment';
  id: Scalars['EntityId']['output'];
  name: ExperimentName;
};

export enum ExperimentFilter {
  User = 'USER',
}

export type ExperimentInterface = {
  id: Scalars['EntityId']['output'];
  name: ExperimentName;
};

export enum ExperimentName {
  Adopt_2024SubblierPriceRise_12PercentIncrease = 'ADOPT_2024_SUBBLIER_PRICE_RISE_12_PERCENT_INCREASE',
  Adopt_2024SubblierPriceRise_20PercentIncrease = 'ADOPT_2024_SUBBLIER_PRICE_RISE_20_PERCENT_INCREASE',
  AdoptChargebeeRenewalsFlow = 'ADOPT_CHARGEBEE_RENEWALS_FLOW',
  AdoptLiteToEssentialUpgrade = 'ADOPT_LITE_TO_ESSENTIAL_UPGRADE',
  BldDiscoSyncingProfile = 'BLD_DISCO_SYNCING_PROFILE',
  BuilderDocRenameWizard = 'BUILDER_DOC_RENAME_WIZARD',
  BuilderMentionTeammatesInNotes = 'BUILDER_MENTION_TEAMMATES_IN_NOTES',
  BuilderSubbieDirectory = 'BUILDER_SUBBIE_DIRECTORY',
  BuilderSubbieDirectoryRestrictAdd = 'BUILDER_SUBBIE_DIRECTORY_RESTRICT_ADD',
  /** @deprecated internal */
  BuilderTest = 'BUILDER_TEST',
  BuilderVisualDocDiffs = 'BUILDER_VISUAL_DOC_DIFFS',
  DiscoUnifiedSubbieProfileRelease_1 = 'DISCO_UNIFIED_SUBBIE_PROFILE_RELEASE_1',
  ItpOtpAuthenticator = 'ITP_OTP_AUTHENTICATOR',
  NetworkV2Poc = 'NETWORK_V2_POC',
  NewInSubbieCreditExperiment = 'NEW_IN_SUBBIE_CREDIT_EXPERIMENT',
  PptChargebeeFreeToPaidFlow = 'PPT_CHARGEBEE_FREE_TO_PAID_FLOW',
  SpecifinderTrialWithOneKeyword = 'SPECIFINDER_TRIAL_WITH_ONE_KEYWORD',
  SubbieActivityFeed = 'SUBBIE_ACTIVITY_FEED',
  SubbieE1UserItpLoginRedirect = 'SUBBIE_E1_USER_ITP_LOGIN_REDIRECT',
  SubbieOnboardingForm = 'SUBBIE_ONBOARDING_FORM',
  SubbieProfileInsights = 'SUBBIE_PROFILE_INSIGHTS',
  SubbieShowProfileOnboardingForFreeAccounts = 'SUBBIE_SHOW_PROFILE_ONBOARDING_FOR_FREE_ACCOUNTS',
  /** @deprecated internal */
  SubbieTest = 'SUBBIE_TEST',
  SubblierProfessionalBundledPackage = 'SUBBLIER_PROFESSIONAL_BUNDLED_PACKAGE',
  SupplierFiftyRedacted = 'SUPPLIER_FIFTY_REDACTED',
  SupplierInsightsEmailUseNewApi = 'SUPPLIER_INSIGHTS_EMAIL_USE_NEW_API',
  SupplierSpecifinderDocFirstPublishedQuery = 'SUPPLIER_SPECIFINDER_DOC_FIRST_PUBLISHED_QUERY',
  SupplierSscTrial = 'SUPPLIER_SSC_TRIAL',
}

export type FieldError = {
  __typename?: 'FieldError';
  field: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type FieldErrors = {
  __typename?: 'FieldErrors';
  errors: Array<FieldError>;
};

export type GenericKeyValuesPair = {
  __typename?: 'GenericKeyValuesPair';
  key: Scalars['String']['output'];
  value: Array<Scalars['String']['output']>;
};

export type GeoPoint = {
  __typename?: 'GeoPoint';
  lat: Scalars['Float']['output'];
  lon: Scalars['Float']['output'];
};

export type HistoricalProject = {
  __typename?: 'HistoricalProject';
  builderName: Scalars['String']['output'];
  category: ProjectCategoryEnum;
  completionDate: Scalars['DateTime']['output'];
  contractSize: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdById: Scalars['Int']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  deletedById?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  linkedProjectId?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  region?: Maybe<Scalars['String']['output']>;
  state: StateEnum;
  updatedAt: Scalars['DateTime']['output'];
  updatedById: Scalars['Int']['output'];
};

export type HistoricalProjectInput = {
  builderName: Scalars['String']['input'];
  category: ProjectCategoryEnum;
  completionDate: Scalars['DateTime']['input'];
  contractSize: Scalars['Int']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  linkedProjectId?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  region?: InputMaybe<Scalars['String']['input']>;
  state: StateEnum;
};

export type HistoricalProjectOrFieldErrors = FieldErrors | HistoricalProject;

export type IntSetting = {
  attribute: Scalars['String']['output'];
  id: Scalars['EntityId']['output'];
  value: Scalars['Int']['output'];
};

export type LocationInput = {
  lat: Scalars['Float']['input'];
  lon: Scalars['Float']['input'];
};

export type MonthlyProfileView = {
  __typename?: 'MonthlyProfileView';
  count: Scalars['Int']['output'];
  id: Scalars['EntityId']['output'];
  month: Scalars['Int']['output'];
  source: ProfileViewLogSource;
  year: Scalars['Int']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Add office address details */
  addOfficeDetails?: Maybe<AccountOfficeOrErrors>;
  addProjectToHistory: HistoricalProjectOrFieldErrors;
  /** Change the name of an account */
  changeAccountName?: Maybe<Account>;
  /** Change business identifier */
  changeBusinessIdentifier: AccountOrErrors;
  /** Change office address details */
  changeOfficeDetails?: Maybe<AccountOffice>;
  /** Change primary contact */
  changePrimaryContact: SubbieProfile;
  /** Change stock trades */
  changeStockTrades: Array<StockTrade>;
  /** Delete account office */
  deleteAccountOffice: AccountOffice;
  deleteHistoricalProject: HistoricalProject;
  /** Change subbie directory opt-in preference */
  editDirectoryOptInSetting: EditIntAccountSettingResponse;
  /** Change subbie-supplier connection opt-out preference */
  editSSCOptOutSetting: EditIntAccountSettingResponse;
  updateAboutUs?: Maybe<StringDataOrFieldErrors>;
  updateCompanyDetails: UniversalProfileResponseOrErrors;
  updateContractSize?: Maybe<ProfileOrFieldErrors>;
  updateCurrentProjectCategories: Array<ProjectCategoryEnum>;
  updateCurrentWorkforceSize?: Maybe<WorkforceSizeEnum>;
  updateDefaultCompanyOffice: UniversalProfileResponseOrErrors;
  updateHistoricalProject: HistoricalProjectOrFieldErrors;
};

export type MutationAddOfficeDetailsArgs = {
  input: AddOfficeDetailsInput;
};

export type MutationAddProjectToHistoryArgs = {
  input: HistoricalProjectInput;
};

export type MutationChangeAccountNameArgs = {
  name: Scalars['String']['input'];
};

export type MutationChangeBusinessIdentifierArgs = {
  businessIdentifier?: InputMaybe<Scalars['String']['input']>;
};

export type MutationChangeOfficeDetailsArgs = {
  input: ChangeOfficeDetailsInput;
};

export type MutationChangePrimaryContactArgs = {
  userId: Scalars['EntityId']['input'];
};

export type MutationChangeStockTradesArgs = {
  stockTradeIds: Array<Scalars['EntityId']['input']>;
};

export type MutationDeleteAccountOfficeArgs = {
  accountOfficeId: Scalars['EntityId']['input'];
};

export type MutationDeleteHistoricalProjectArgs = {
  id: Scalars['ID']['input'];
};

export type MutationEditDirectoryOptInSettingArgs = {
  optIn: Scalars['Boolean']['input'];
};

export type MutationEditSscOptOutSettingArgs = {
  optOut: Scalars['Boolean']['input'];
};

export type MutationUpdateAboutUsArgs = {
  aboutUs?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateCompanyDetailsArgs = {
  input?: InputMaybe<UpdateCompanyDetailsInput>;
};

export type MutationUpdateContractSizeArgs = {
  contractSizeMax?: InputMaybe<ProfileContractSizeMaxEnum>;
  contractSizeMin?: InputMaybe<ProfileContractSizeMinEnum>;
};

export type MutationUpdateCurrentProjectCategoriesArgs = {
  projectCategories: Array<ProjectCategoryEnum>;
};

export type MutationUpdateCurrentWorkforceSizeArgs = {
  workforceSize?: InputMaybe<WorkforceSizeEnum>;
};

export type MutationUpdateDefaultCompanyOfficeArgs = {
  input?: InputMaybe<UpdateDefaultCompanyOfficeInput>;
};

export type MutationUpdateHistoricalProjectArgs = {
  id: Scalars['ID']['input'];
  input: HistoricalProjectInput;
};

export type NewProfileResult = {
  __typename?: 'NewProfileResult';
  aboutUs?: Maybe<Scalars['String']['output']>;
  /** Ascension account id */
  accountId?: Maybe<Scalars['ID']['output']>;
  contacts: Array<Contact>;
  contractSizeMax?: Maybe<Scalars['Int']['output']>;
  contractSizeMin?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  /** Profile id */
  id: Scalars['ID']['output'];
  lastActiveAt?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  offices: Array<ProfileSearchAccountOffice>;
  projectCategoryIds: Array<Scalars['Int']['output']>;
  stockTradeIds: Array<Scalars['Int']['output']>;
  subTradeIds: Array<Scalars['Int']['output']>;
  workforceSize?: Maybe<Scalars['Int']['output']>;
};

export type NewSearchProfilesResult = {
  __typename?: 'NewSearchProfilesResult';
  count: Scalars['Int']['output'];
  currentPage: Scalars['Int']['output'];
  results: Array<NewProfileResult>;
  totalCount: Scalars['Int']['output'];
};

export type Office = {
  address?: Maybe<Address>;
  email?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  timezone: Scalars['String']['output'];
};

export type PaginatedProjectSearchResult = {
  __typename?: 'PaginatedProjectSearchResult';
  count: Scalars['Int']['output'];
  projects: Array<Project>;
};

export type PaginationInput = {
  page?: InputMaybe<Scalars['Int']['input']>;
  resultsPerPage?: InputMaybe<Scalars['Int']['input']>;
};

export type Person = {
  __typename?: 'Person';
  ascensionId?: Maybe<Scalars['EntityId']['output']>;
  contactable?: Maybe<Contactable>;
  createdAt: Scalars['DateTime']['output'];
  createdById: Scalars['Int']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  deletedById?: Maybe<Scalars['Int']['output']>;
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isDefaultContact: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  office?: Maybe<ProfileOffice>;
  positionTitle?: Maybe<Scalars['String']['output']>;
  profile: Profile;
  updatedAt: Scalars['DateTime']['output'];
  updatedById: Scalars['Int']['output'];
};

export type Profile = {
  __typename?: 'Profile';
  aboutUs?: Maybe<Scalars['String']['output']>;
  account?: Maybe<ProfileAccount>;
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  archivedById?: Maybe<Scalars['Int']['output']>;
  companyNumber?: Maybe<Scalars['String']['output']>;
  completionStatus: Scalars['Int']['output'];
  contactable?: Maybe<Contactable>;
  contractSizeMax?: Maybe<ProfileContractSizeMaxEnum>;
  contractSizeMin?: Maybe<ProfileContractSizeMinEnum>;
  createdAt: Scalars['DateTime']['output'];
  createdById: Scalars['Int']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  deletedById?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  isOptedIntoDirectory: Scalars['Boolean']['output'];
  isShadowProfile: Scalars['Boolean']['output'];
  isTestAccount: Scalars['Boolean']['output'];
  lastActiveAt?: Maybe<Scalars['DateTime']['output']>;
  memberSince?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  offices: Array<ProfileOffice>;
  people: Array<Person>;
  /** A list of project categories. May return an empty list but never null */
  projectCategories: Array<ProjectCategoryEnum>;
  /** A list of historical projects. May return an empty list but never null */
  projectHistory: Array<HistoricalProject>;
  trades: Array<ProfileTrade>;
  updatedAt: Scalars['DateTime']['output'];
  updatedById: Scalars['Int']['output'];
  workforceSize?: Maybe<WorkforceSizeEnum>;
};

export type ProfileAccount = {
  __typename?: 'ProfileAccount';
  id?: Maybe<Scalars['EntityId']['output']>;
  type?: Maybe<Scalars['Int']['output']>;
  typeName?: Maybe<Scalars['String']['output']>;
};

export type ProfileAddress = {
  __typename?: 'ProfileAddress';
  address1: Scalars['String']['output'];
  address2?: Maybe<Scalars['String']['output']>;
  address3?: Maybe<Scalars['String']['output']>;
  ascensionId?: Maybe<Scalars['EntityId']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  /** Country name */
  country: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdById: Scalars['Int']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  deletedById?: Maybe<Scalars['Int']['output']>;
  district?: Maybe<Scalars['String']['output']>;
  fullAddress: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  postcode: Scalars['String']['output'];
  province?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  shortAddress: Scalars['String']['output'];
  /** State shortcode */
  state?: Maybe<Scalars['String']['output']>;
  suburb?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  updatedById: Scalars['Int']['output'];
};

export enum ProfileContractSizeMaxEnum {
  Size_1M = 'SIZE_1M',
  Size_5M = 'SIZE_5M',
  Size_10MPlus = 'SIZE_10M_PLUS',
  Size_10k = 'SIZE_10k',
  Size_50k = 'SIZE_50k',
  Size_100k = 'SIZE_100k',
  Size_250k = 'SIZE_250k',
  Size_500k = 'SIZE_500k',
}

export enum ProfileContractSizeMinEnum {
  Size_1M = 'SIZE_1M',
  Size_5M = 'SIZE_5M',
  Size_10k = 'SIZE_10k',
  Size_50k = 'SIZE_50k',
  Size_100k = 'SIZE_100k',
  Size_250k = 'SIZE_250k',
  Size_500k = 'SIZE_500k',
}

export type ProfileData = {
  account?: Maybe<UniversalProfileAccount>;
  categories: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  offices: Array<UniversalProfileOffice>;
  people: Array<UniversalProfilePerson>;
  source: Source;
  trades: Array<UniversalProfileTrade>;
};

export type ProfileOffice = {
  __typename?: 'ProfileOffice';
  address: ProfileAddress;
  ascensionId?: Maybe<Scalars['EntityId']['output']>;
  contactable?: Maybe<Contactable>;
  createdAt: Scalars['DateTime']['output'];
  createdById: Scalars['Int']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  deletedById?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  isDefaultOffice: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  profile: Profile;
  updatedAt: Scalars['DateTime']['output'];
  updatedById: Scalars['Int']['output'];
};

export type ProfileOrCompany = AddressBookCompanyResult | NewProfileResult;

export type ProfileOrFieldErrors = FieldErrors | Profile;

/** Named ProfileResponse to avoid a conflict with the Profile type */
export type ProfileResponse = {
  __typename?: 'ProfileResponse';
  profile: UnconnectedProfileData;
};

export type ProfileResult = {
  __typename?: 'ProfileResult';
  aboutUs?: Maybe<Scalars['String']['output']>;
  contractSizeMax?: Maybe<Scalars['Int']['output']>;
  contractSizeMin?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  /** Ascension account id */
  id: Scalars['ID']['output'];
  lastActiveAt?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  offices: Array<ProfileSearchAccountOffice>;
  projectCategoryIds: Array<Scalars['Int']['output']>;
  stockTradeIds: Array<Scalars['Int']['output']>;
  subTradeIds: Array<Scalars['Int']['output']>;
  workforceSize?: Maybe<Scalars['Int']['output']>;
};

export type ProfileSearchAccountOffice = {
  __typename?: 'ProfileSearchAccountOffice';
  address?: Maybe<ProfileSearchAddress>;
  email?: Maybe<Scalars['String']['output']>;
  fax?: Maybe<Scalars['String']['output']>;
  /** Ascension account office id */
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  location?: Maybe<GeoPoint>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export type ProfileSearchAddress = {
  __typename?: 'ProfileSearchAddress';
  address1: Scalars['String']['output'];
  address2?: Maybe<Scalars['String']['output']>;
  address3?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<ProfileSearchCountry>;
  district?: Maybe<Scalars['String']['output']>;
  /** Ascension address id */
  id: Scalars['ID']['output'];
  postcode?: Maybe<Scalars['String']['output']>;
  province?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  state?: Maybe<ProfileSearchState>;
  suburb?: Maybe<Scalars['String']['output']>;
};

export type ProfileSearchCountry = {
  __typename?: 'ProfileSearchCountry';
  /** Ascension country id */
  id: Scalars['ID']['output'];
  shortName: Scalars['String']['output'];
};

export type ProfileSearchState = {
  __typename?: 'ProfileSearchState';
  /** Ascension state id */
  id: Scalars['ID']['output'];
  shortName: Scalars['String']['output'];
};

export type ProfileStockTrade = {
  __typename?: 'ProfileStockTrade';
  ascensionId: Scalars['EntityId']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdById: Scalars['Int']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  deletedById?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  updatedById: Scalars['Int']['output'];
};

export type ProfileTrade = {
  __typename?: 'ProfileTrade';
  createdAt: Scalars['DateTime']['output'];
  createdById: Scalars['Int']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  deletedById?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  profile: Profile;
  trade: ProfileStockTrade;
  updatedAt: Scalars['DateTime']['output'];
  updatedById: Scalars['Int']['output'];
};

export enum ProfileViewLogSource {
  Addressbook = 'ADDRESSBOOK',
  Directory = 'DIRECTORY',
  Page = 'PAGE',
  Quote = 'QUOTE',
  Rfq = 'RFQ',
}

export type Project = Entity &
  ProjectInterface & {
    __typename?: 'Project';
    address: Address;
    awardedAt?: Maybe<Scalars['DateTime']['output']>;
    createdAt: Scalars['DateTime']['output'];
    id: Scalars['EntityId']['output'];
    name: Scalars['String']['output'];
    stageCategoryName?: Maybe<Scalars['String']['output']>;
    status: ProjectStatus;
    tenderQuoteDueAt?: Maybe<Scalars['DateTime']['output']>;
    unlockedAt?: Maybe<Scalars['DateTime']['output']>;
    updatedAt: Scalars['DateTime']['output'];
  };

export enum ProjectCategoryEnum {
  AgedCare = 'AGED_CARE',
  Civil = 'CIVIL',
  Commercial = 'COMMERCIAL',
  Corrections = 'CORRECTIONS',
  DataCentre = 'DATA_CENTRE',
  Defence = 'DEFENCE',
  Education = 'EDUCATION',
  Entertainment = 'ENTERTAINMENT',
  FitOut = 'FIT_OUT',
  Government = 'GOVERNMENT',
  Health = 'HEALTH',
  Hospitality = 'HOSPITALITY',
  IndustrialHeavy = 'INDUSTRIAL_HEAVY',
  IndustrialLight = 'INDUSTRIAL_LIGHT',
  Medical = 'MEDICAL',
  MixedUse = 'MIXED_USE',
  Other = 'OTHER',
  Pharmaceutical = 'PHARMACEUTICAL',
  Recreation = 'RECREATION',
  Refurbishment = 'REFURBISHMENT',
  Residential = 'RESIDENTIAL',
  Retail = 'RETAIL',
  Tourism = 'TOURISM',
}

export type ProjectInterface = {
  address: Address;
  id: Scalars['EntityId']['output'];
  name: Scalars['String']['output'];
  stageCategoryName?: Maybe<Scalars['String']['output']>;
  status: ProjectStatus;
};

export enum ProjectStatus {
  Awarded = 'AWARDED',
  Closed = 'CLOSED',
  Open = 'OPEN',
}

export type Query = {
  __typename?: 'Query';
  /** Retrieve an account by its id */
  account?: Maybe<Account>;
  /** Fetch offices for the current account */
  accountOffices: Array<AccountOffice>;
  /** Returns an array of profile features that are enabled for account by account id */
  availableFeatures: CompanyProfileFeatures;
  countries: Array<UniversalCountry>;
  /** Retrieve current account */
  currentAccount?: Maybe<Account>;
  currentProfile?: Maybe<Profile>;
  /** Retrieve the currently logged in user */
  currentUser: User;
  /** Breakdown of daily views to current account's profiles from network. */
  dailyProfileViews: Array<DailyProfileView>;
  defaultAddressBook: AddressBook;
  /** Retrieve experiment status */
  experimentStatus: Scalars['Boolean']['output'];
  /** Retrieve experiments */
  experiments: Array<Experiment>;
  /** Has a user of this account opted out of the subbie-supplier connection? */
  hasOptedOutOfSSC: Scalars['Boolean']['output'];
  /** Whether user is authorised to opt into subbie directory */
  isAuthorisedToOptIntoDirectory: Scalars['Boolean']['output'];
  isFeatureEnabled: Scalars['Boolean']['output'];
  /** Breakdown of views to current accounts profiles from network */
  monthlyProfileViews: Array<MonthlyProfileView>;
  presignedLogoLink: Scalars['String']['output'];
  profile?: Maybe<Profile>;
  profileByAccountId?: Maybe<Profile>;
  profileById?: Maybe<Profile>;
  /** Returns results ordered by score */
  searchE1Projects: PaginatedProjectSearchResult;
  searchMatchingProfiles: SearchMatchingProfilesResult;
  /** @deprecated Use searchProfilesNew */
  searchProfiles: SearchProfilesResult;
  searchProfilesAndCompanies: SearchProfilesAndCompaniesResult;
  searchProfilesNew: NewSearchProfilesResult;
  states: Array<UniversalState>;
  /** Fetch all stock trades */
  stockTrades: Array<StockTrade>;
  /** Retrieve subbie profile data by account id */
  subbieProfile: SubbieProfile;
  universalProfile: UniversalProfileResponse;
};

export type QueryAccountArgs = {
  id: Scalars['EntityId']['input'];
};

export type QueryAvailableFeaturesArgs = {
  id: Scalars['EntityId']['input'];
};

export type QueryExperimentStatusArgs = {
  name: ExperimentName;
};

export type QueryExperimentsArgs = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ExperimentFilter>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type QueryIsFeatureEnabledArgs = {
  featureName: Scalars['String']['input'];
};

export type QueryProfileArgs = {
  id: Scalars['ID']['input'];
};

export type QueryProfileByAccountIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryProfileByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QuerySearchE1ProjectsArgs = {
  input: SearchProjectsInput;
};

export type QuerySearchMatchingProfilesArgs = {
  search: SearchMatchingProfilesInput;
};

export type QuerySearchProfilesArgs = {
  search: SearchProfilesInput;
};

export type QuerySearchProfilesAndCompaniesArgs = {
  search: SearchProfilesAndCompaniesInput;
};

export type QuerySearchProfilesNewArgs = {
  search: SearchProfilesInput;
};

export type QuerySubbieProfileArgs = {
  id: Scalars['EntityId']['input'];
};

export type QueryUniversalProfileArgs = {
  input: UniversalProfileInput;
};

export enum ResultType {
  AbCompany = 'AB_COMPANY',
  Both = 'BOTH',
  Profile = 'PROFILE',
}

export type SearchMatchingProfilesInput = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  profileId?: InputMaybe<Scalars['String']['input']>;
};

export type SearchMatchingProfilesResult = {
  __typename?: 'SearchMatchingProfilesResult';
  /** Describes the fields used for matching and the values on which the matching was performed */
  highlights: Array<GenericKeyValuesPair>;
  results: Array<ProfileOrCompany>;
  /** What was used to find the matching results */
  searched?: Maybe<ProfileOrCompany>;
};

export type SearchProfilesAndCompaniesInput = {
  connectionType?: InputMaybe<ConnectionType>;
  contractSizeMax?: InputMaybe<ContractSizeMaxEnum>;
  contractSizeMin?: InputMaybe<ContractSizeMinEnum>;
  distance?: InputMaybe<DistanceFromInput>;
  listIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Defaults to 200 results, no pagination */
  pagination?: InputMaybe<PaginationInput>;
  projectCategoryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  query?: InputMaybe<Scalars['String']['input']>;
  resultType: ResultType;
  /** Defaults to build-in multi-factor ranking */
  sorting?: InputMaybe<SortProfilesInput>;
  stockTradeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  tagIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  tradeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  workforceSize?: InputMaybe<WorkforceSizeEnum>;
};

export type SearchProfilesAndCompaniesResult = {
  __typename?: 'SearchProfilesAndCompaniesResult';
  count: Scalars['Int']['output'];
  currentPage: Scalars['Int']['output'];
  results: Array<ProfileOrCompany>;
  totalCount: Scalars['Int']['output'];
};

export type SearchProfilesInput = {
  distance?: InputMaybe<DistanceFromInput>;
  pagination?: InputMaybe<PaginationInput>;
  projectCategoryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  query?: InputMaybe<Scalars['String']['input']>;
  sorting?: InputMaybe<SortProfilesInput>;
  stockTradeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  subTradeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type SearchProfilesResult = {
  __typename?: 'SearchProfilesResult';
  count: Scalars['Int']['output'];
  currentPage: Scalars['Int']['output'];
  results: Array<ProfileResult>;
  totalCount: Scalars['Int']['output'];
};

export type SearchProjectsInput = {
  query: Scalars['String']['input'];
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum SortField {
  ContractSizeMin = 'CONTRACT_SIZE_MIN',
  Distance = 'DISTANCE',
  LastActive = 'LAST_ACTIVE',
  Name = 'NAME',
  WorkforceSize = 'WORKFORCE_SIZE',
}

export type SortProfilesInput = {
  /** Location must be supplied if sorting by SortDirection.DISTANCE */
  location?: InputMaybe<LocationInput>;
  sortBy?: InputMaybe<SortField>;
  /** N.b. when sorting by distance, DESC is closest to furthest */
  sortDirection?: InputMaybe<SortDirection>;
};

export enum Source {
  AddressBook = 'ADDRESS_BOOK',
  SubbieDirectory = 'SUBBIE_DIRECTORY',
}

export type State = Entity & {
  __typename?: 'State';
  country: Country;
  id: Scalars['EntityId']['output'];
  name: Scalars['String']['output'];
  shortName: Scalars['String']['output'];
};

export enum StateEnum {
  Act = 'ACT',
  Nsw = 'NSW',
  Nt = 'NT',
  Other = 'Other',
  Qld = 'QLD',
  Sa = 'SA',
  Tas = 'TAS',
  Vic = 'VIC',
  Wa = 'WA',
}

export type StockTrade = Entity &
  StockTradeInterface & {
    __typename?: 'StockTrade';
    accounts: Array<Account>;
    discipline: StockTradeDiscipline;
    id: Scalars['EntityId']['output'];
    name: Scalars['String']['output'];
    trades?: Maybe<Array<Trade>>;
  };

export type StockTradeDiscipline = Entity & {
  __typename?: 'StockTradeDiscipline';
  id: Scalars['EntityId']['output'];
  name: Scalars['String']['output'];
  trades: Array<StockTrade>;
};

export type StockTradeInterface = {
  name: Scalars['String']['output'];
};

export type StringData = {
  __typename?: 'StringData';
  data?: Maybe<Scalars['String']['output']>;
};

export type StringDataOrFieldErrors = FieldErrors | StringData;

export type SubbieProfile = Entity & {
  __typename?: 'SubbieProfile';
  accountOffices: Array<AccountOffice>;
  businessIdentifier?: Maybe<Scalars['String']['output']>;
  contacts: Array<SubbieProfileContact>;
  defaultOffice: AccountOffice;
  id: Scalars['EntityId']['output'];
  lastActiveAt?: Maybe<Scalars['DateTime']['output']>;
  memberSince: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  preferredContact?: Maybe<SubbieProfileContact>;
  trades: Array<StockTrade>;
  websiteAddress?: Maybe<Scalars['String']['output']>;
};

export type SubbieProfileContact = Entity &
  UserInterface & {
    __typename?: 'SubbieProfileContact';
    email?: Maybe<Scalars['String']['output']>;
    firstName?: Maybe<Scalars['String']['output']>;
    fullName?: Maybe<Scalars['String']['output']>;
    id: Scalars['EntityId']['output'];
    lastName?: Maybe<Scalars['String']['output']>;
    phone?: Maybe<Scalars['String']['output']>;
  };

export type SyncedProfiles = {
  __typename?: 'SyncedProfiles';
  connectedProfile: ConnectedProfileData;
  profile: UnconnectedProfileData;
};

export type Trade = Entity &
  TradeInterface & {
    __typename?: 'Trade';
    discipline?: Maybe<TradeDiscipline>;
    id: Scalars['EntityId']['output'];
    name: Scalars['String']['output'];
    stockTrade?: Maybe<StockTrade>;
  };

export type TradeDiscipline = Entity &
  TradeDisciplineInterface & {
    __typename?: 'TradeDiscipline';
    id: Scalars['EntityId']['output'];
    name: Scalars['String']['output'];
    orderIndex: Scalars['Int']['output'];
    trades: Array<Trade>;
  };

export type TradeDisciplineInterface = {
  name: Scalars['String']['output'];
  orderIndex: Scalars['Int']['output'];
};

export type TradeInterface = {
  name: Scalars['String']['output'];
};

export type UnconnectedProfileData = ProfileData & {
  __typename?: 'UnconnectedProfileData';
  account?: Maybe<UniversalProfileAccount>;
  categories: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastActiveAt?: Maybe<Scalars['DateTime']['output']>;
  /** UnconnectedProfile only fields */
  memberSince?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  offices: Array<UniversalProfileOffice>;
  people: Array<UniversalProfilePerson>;
  projectHistory: Array<UniversalHistoricalProject>;
  source: Source;
  trades: Array<UniversalProfileTrade>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UniversalCountry = {
  __typename?: 'UniversalCountry';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  shortName: Scalars['String']['output'];
};

export type UniversalHistoricalProject = {
  __typename?: 'UniversalHistoricalProject';
  builderName: Scalars['String']['output'];
  category: Scalars['String']['output'];
  completionDate: Scalars['DateTime']['output'];
  contractSize: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdById: Scalars['Int']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  deletedById?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  linkedProjectId?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  region?: Maybe<Scalars['String']['output']>;
  state: UniversalState;
  updatedAt: Scalars['DateTime']['output'];
  updatedById: Scalars['Int']['output'];
};

export type UniversalProfileAccount = {
  __typename?: 'UniversalProfileAccount';
  id?: Maybe<Scalars['EntityId']['output']>;
  type?: Maybe<Scalars['Int']['output']>;
  typeName?: Maybe<Scalars['String']['output']>;
};

export type UniversalProfileAddress = {
  __typename?: 'UniversalProfileAddress';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  address3?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<UniversalCountry>;
  id: Scalars['ID']['output'];
  postcode?: Maybe<Scalars['String']['output']>;
  province?: Maybe<Scalars['String']['output']>;
  shortAddress: Scalars['String']['output'];
  state?: Maybe<UniversalState>;
  suburb?: Maybe<Scalars['String']['output']>;
};

export type UniversalProfileContactable = {
  __typename?: 'UniversalProfileContactable';
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  web?: Maybe<Scalars['String']['output']>;
};

export type UniversalProfileCustomField = {
  __typename?: 'UniversalProfileCustomField';
  fieldId: Scalars['Int']['output'];
  fieldName: Scalars['String']['output'];
  fieldValue?: Maybe<Scalars['String']['output']>;
};

export type UniversalProfileInput = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
  addressbookCompanyId?: InputMaybe<Scalars['ID']['input']>;
  profileId?: InputMaybe<Scalars['ID']['input']>;
};

export type UniversalProfileMutationSuccessResponse = {
  __typename?: 'UniversalProfileMutationSuccessResponse';
  universalProfile?: Maybe<UniversalProfileResponse>;
};

export type UniversalProfileOffice = {
  __typename?: 'UniversalProfileOffice';
  address: UniversalProfileAddress;
  contactable?: Maybe<UniversalProfileContactable>;
  id: Scalars['ID']['output'];
  isDefaultOffice: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type UniversalProfilePerson = {
  __typename?: 'UniversalProfilePerson';
  ascensionId?: Maybe<Scalars['ID']['output']>;
  contactable?: Maybe<UniversalProfileContactable>;
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isDefaultContact: Scalars['Boolean']['output'];
  isPreferred: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  positionTitle?: Maybe<Scalars['String']['output']>;
  types: Array<ContactType>;
};

export type UniversalProfileResponse =
  | ConnectedProfile
  | ProfileResponse
  | SyncedProfiles;

export type UniversalProfileResponseOrErrors =
  | Errors
  | UniversalProfileMutationSuccessResponse;

export type UniversalProfileTrade = {
  __typename?: 'UniversalProfileTrade';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type UniversalState = {
  __typename?: 'UniversalState';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  shortName: Scalars['String']['output'];
};

export type UpdateCompanyDetailsInput = {
  companyId: Scalars['ID']['input'];
  companyListIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  custom1?: InputMaybe<Scalars['String']['input']>;
  custom2?: InputMaybe<Scalars['String']['input']>;
  custom3?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  tradeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type UpdateDefaultCompanyOfficeInput = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  address3?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['ID']['input'];
  country?: InputMaybe<Scalars['ID']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  province?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['ID']['input']>;
  suburb?: InputMaybe<Scalars['String']['input']>;
};

export type User = Entity &
  UserInterface & {
    __typename?: 'User';
    account?: Maybe<Account>;
    address?: Maybe<Address>;
    createdAt: Scalars['DateTime']['output'];
    deletedAt?: Maybe<Scalars['DateTime']['output']>;
    email: Scalars['String']['output'];
    firstName: Scalars['String']['output'];
    fullName: Scalars['String']['output'];
    fullNameAndEmail: Scalars['String']['output'];
    id: Scalars['EntityId']['output'];
    imperialUnitsSetting: Scalars['Boolean']['output'];
    isAdmin: Scalars['Boolean']['output'];
    isSystem: Scalars['Boolean']['output'];
    lastActiveAt?: Maybe<Scalars['DateTime']['output']>;
    lastName?: Maybe<Scalars['String']['output']>;
    phone?: Maybe<Scalars['String']['output']>;
  };

export type UserInterface = {
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
};

export enum WorkforceSizeEnum {
  Range_0_5 = 'RANGE_0_5',
  Range_6_20 = 'RANGE_6_20',
  Range_21_50 = 'RANGE_21_50',
  Range_51_250 = 'RANGE_51_250',
  Range_250Plus = 'RANGE_250_PLUS',
  Range_251Plus = 'RANGE_251_PLUS',
}
