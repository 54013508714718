import { createContext } from 'react';
import { UserDetailsFragment } from 'modules/UserInfo/__generated__/queries.types';

export type UserContextProps = {
  loading: boolean;
  // lets us only do one null check
  context: null | {
    user: UserDetailsFragment;
  };
};

export const UserContext = createContext<UserContextProps>({
  loading: true,
  context: null,
});
