import { gql } from '@apollo/client';

export const GET_PROFILE = gql`
  query GetProfileForOnboardingForm {
    currentProfile {
      id
      contractSizeMin
      contractSizeMax
      projectCategories
      workforceSize
      companyNumber
    }
  }
`;
