import React from 'react';
import {
  ApolloClient,
  ApolloProvider,
  from,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { TokenCache } from './services/TokenCache';

/**
 * See Webpack DefinePlugin config
 */
declare const __VERSION__: string;

const createApolloClient = (uri: string, IDP_URI: string) => {
  const tokenCache = new TokenCache(`${IDP_URI}/gateway/authorise`);

  const authLink = setContext(async (_, { headers }) => {
    try {
      return {
        headers: {
          authorization: `Bearer ${await tokenCache.getToken()}`,
          ...headers,
        },
      };
    } catch (err) {}
  });

  const httpLink = new HttpLink({ uri });

  return new ApolloClient({
    defaultOptions: {
      mutate: {
        errorPolicy: 'all', // this ensure that we don't have to catch errors explicitly
      },
    },
    link: from([authLink, httpLink]),
    cache: new InMemoryCache(),
    name: 'profile-onboarding-mf',
    version: __VERSION__,
  });
};

interface ApiProviderConfig {
  API_URI: string;
  IDP_URI: string;
}

interface AuthProviderProps {
  children: React.ReactNode;
  envConfig: ApiProviderConfig;
}

export const ApiProvider = ({
  children,
  envConfig: { API_URI, IDP_URI },
}: AuthProviderProps) => {
  const client = createApolloClient(API_URI, IDP_URI);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
