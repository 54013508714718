import React from 'react';
import { SingleSelectDropdownField } from 'modules/FinalFormFields/SingleSelectDropdownField';
import { getWorkforceSizeLabel } from './utils';
import {
  ProfileOnboardingFieldLabels,
  ProfileOnboardingFieldNames,
} from 'components/types';
import { WorkforceSizeEnum } from 'types/graphql.types';

export const WorkforceSizeEnumMap = {
  [WorkforceSizeEnum.Range_0_5]: 1,
  [WorkforceSizeEnum.Range_6_20]: 2,
  [WorkforceSizeEnum.Range_21_50]: 3,
  [WorkforceSizeEnum.Range_51_250]: 4,
  [WorkforceSizeEnum.Range_250Plus]: 5,
  [WorkforceSizeEnum.Range_251Plus]: 6,
};

export type WorkforceSizeOption = {
  value: WorkforceSizeEnum | null;
  label: string;
};

export const EMPTY_WORKFORCE = {
  value: null,
  label: '-',
};

export const enumToWorkforceSizeInputOption = (value: WorkforceSizeEnum) => ({
  value,
  label: getWorkforceSizeLabel(value),
});

const workforceSizeOptions = Object.keys(WorkforceSizeEnum)
  .map((value: unknown) => value as keyof typeof WorkforceSizeEnum)
  .map((value) => WorkforceSizeEnum[value])
  .map(enumToWorkforceSizeInputOption)
  .sort(
    (a, b) => WorkforceSizeEnumMap[a.value] - WorkforceSizeEnumMap[b.value],
  );

export const WorkforceSize = () => (
  <div>
    <SingleSelectDropdownField<WorkforceSizeOption>
      id="workforce-size"
      label={ProfileOnboardingFieldLabels.WorkforceSize}
      hideLabel
      placeholder={ProfileOnboardingFieldLabels.WorkforceSize}
      fieldName={ProfileOnboardingFieldNames.WorkforceSize}
      options={[EMPTY_WORKFORCE, ...workforceSizeOptions]}
    />
  </div>
);
