import { CategoryOption } from './Categories';
import {
  ProfileContractSizeMaxEnum as ContractSizeMaxEnum,
  ProfileContractSizeMinEnum as ContractSizeMinEnum,
  WorkforceSizeEnum,
} from 'types/graphql.types';

export enum ProfileOnboardingFieldNames {
  CompanyABN = 'businessIdentifier',
  MinContractSize = 'contractSizeMin',
  MaxContractSize = 'contractSizeMax',
  MinAndMaxContractSize = 'contractSizeMinAndMax',
  WorkforceSize = 'workforceSize',
  ProjectCategories = 'projectCategories',
}

export enum ProfileOnboardingFieldLabels {
  CompanyABN = 'ABN',
  MinContractSize = 'Minimum contract value',
  MaxContractSize = 'Maximum contract value',
  WorkforceSize = 'Workforce size',
  ProjectCategories = 'Project Categories',
}

export type ProfileOnboardingFieldValues = {
  [ProfileOnboardingFieldNames.CompanyABN]: string;
  [ProfileOnboardingFieldNames.MinContractSize]: {
    value: ContractSizeMinEnum | null;
    label: string;
  };
  [ProfileOnboardingFieldNames.MaxContractSize]: {
    value: ContractSizeMaxEnum | null;
    label: string;
  };
  [ProfileOnboardingFieldNames.WorkforceSize]: {
    value: WorkforceSizeEnum | null;
    label: string;
  };
  [ProfileOnboardingFieldNames.ProjectCategories]: CategoryOption[];
};
