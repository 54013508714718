import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { App } from './App';
import {
  DirectoryOnboarding,
  DirectoryOnboardingProps,
} from './views/DirectoryOnboarding';
import {
  ProfileOnboarding,
  ProfileOnboardingProps,
} from './views/ProfileOnboarding';

window.mountProfileOnboardingMFE = (
  containerId: string,
  props: ProfileOnboardingProps,
) => {
  const { onSuccess } = props;
  render(
    <App>
      <ProfileOnboarding onSuccess={onSuccess} />
    </App>,
    document.getElementById(containerId),
  );
};

window.mountDirectoryOnboardingMFE = (
  containerId: string,
  props: DirectoryOnboardingProps,
) => {
  const { onSuccess, onCancel } = props;
  render(
    <App>
      <DirectoryOnboarding onSuccess={onSuccess} onCancel={onCancel} />
    </App>,
    document.getElementById(containerId),
  );
};

window.unmountProfileOnboardingMFE = (containerId: string) => {
  const el = document.getElementById(containerId);
  if (el) {
    unmountComponentAtNode(el);
  }
};

window.unmountDirectoryOnboardingMFE = (containerId: string) => {
  const el = document.getElementById(containerId);
  if (el) {
    unmountComponentAtNode(el);
  }
};
