import { useMutation, useQuery } from '@apollo/client';
import { enumToCategoryInputOption } from '../../components/Categories';
import { EMPTY_CONTRACT_SIZE } from '../../components/ContractSize';
import { enumToContractSizeInputOption } from '../../components/ContractSize/ContractSize';
import {
  EMPTY_WORKFORCE,
  enumToWorkforceSizeInputOption,
} from '../../components/WorkforceSize/WorkforceSize';
import { GetProfileForOnboardingFormQuery } from './__generated__/queries.types';
import { GET_PROFILE } from './queries';
import {
  UpdateCompanyProfileMutation,
  UpdateCompanyProfileMutationVariables,
} from './__generated__/mutations.types';
import { UPDATE_COMPANY_PROFILE } from './mutations';
import {
  ProfileOnboardingFieldNames,
  ProfileOnboardingFieldValues,
} from 'components/types';

export const useSubmitForm = () => {
  const [updateCompanyProfile, { error, loading }] = useMutation<
    UpdateCompanyProfileMutation,
    UpdateCompanyProfileMutationVariables
  >(UPDATE_COMPANY_PROFILE);

  const submit = async ({
    businessIdentifier,
    contractSizeMax,
    contractSizeMin,
    workforceSize,
    projectCategories,
  }: ProfileOnboardingFieldValues) =>
    updateCompanyProfile({
      variables: {
        businessIdentifier: businessIdentifier.replaceAll(' ', ''),
        contractSizeMax: contractSizeMax.value,
        contractSizeMin: contractSizeMin.value,
        workforceSize: workforceSize?.value ?? null,
        projectCategories: (projectCategories ?? []).map(({ value }) => value),
      },
    });

  return { submit, error: error ?? undefined, loading };
};

type InitialFormValuesType =
  | {
      loading: true;
      initialValues: null;
    }
  | {
      loading: false;
      initialValues: ProfileOnboardingFieldValues;
    };

export const useInitialFormValues = (): InitialFormValuesType => {
  const { data, loading } =
    useQuery<GetProfileForOnboardingFormQuery>(GET_PROFILE);

  if (loading) {
    return {
      initialValues: null,
      loading: true,
    };
  }

  if (data === undefined || data.currentProfile == null) {
    return {
      initialValues: {
        [ProfileOnboardingFieldNames.CompanyABN]: '',
        [ProfileOnboardingFieldNames.ProjectCategories]: [],
        [ProfileOnboardingFieldNames.WorkforceSize]: EMPTY_WORKFORCE,
        [ProfileOnboardingFieldNames.MaxContractSize]: EMPTY_CONTRACT_SIZE,
        [ProfileOnboardingFieldNames.MinContractSize]: EMPTY_CONTRACT_SIZE,
      },
      loading: false,
    };
  }

  const {
    projectCategories,
    workforceSize,
    contractSizeMax,
    contractSizeMin,
    companyNumber,
  } = data.currentProfile;

  return {
    initialValues: {
      [ProfileOnboardingFieldNames.CompanyABN]: companyNumber ?? '',
      [ProfileOnboardingFieldNames.ProjectCategories]: projectCategories.map(
        enumToCategoryInputOption,
      ),
      [ProfileOnboardingFieldNames.WorkforceSize]: workforceSize
        ? enumToWorkforceSizeInputOption(workforceSize)
        : EMPTY_WORKFORCE,
      [ProfileOnboardingFieldNames.MaxContractSize]: contractSizeMax
        ? enumToContractSizeInputOption(contractSizeMax)
        : EMPTY_CONTRACT_SIZE,
      [ProfileOnboardingFieldNames.MinContractSize]: contractSizeMin
        ? enumToContractSizeInputOption(contractSizeMin)
        : EMPTY_CONTRACT_SIZE,
    },
    loading: false,
  };
};
