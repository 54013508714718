import { WorkforceSizeEnum } from 'types/graphql.types';

export const getWorkforceSizeLabel = (
  workforceSize: WorkforceSizeEnum,
): string => {
  const WORKFORCE_SIZE_PREFIX = 'RANGE_';
  const WORKFORCE_SIZE_PLUS_SUFFIX = '_PLUS';
  const WORKFORCE_SIZE_PREFIX_LEN = WORKFORCE_SIZE_PREFIX.length;
  const WORKFORCE_SIZE_PLUS_SUFFIX_LEN = WORKFORCE_SIZE_PLUS_SUFFIX.length;

  if (workforceSize.includes(WORKFORCE_SIZE_PLUS_SUFFIX)) {
    // The highest size has PLUS as suffix in the enum
    return (
      workforceSize
        .substring(
          WORKFORCE_SIZE_PREFIX_LEN,
          workforceSize.length - WORKFORCE_SIZE_PLUS_SUFFIX_LEN,
        )
        .replace(/_/g, '-') + '+'
    );
  }
  return workforceSize.substring(WORKFORCE_SIZE_PREFIX_LEN).replace(/_/g, '-');
};
