/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { joinClassNames } from '@estimateone/frontend-components';
import styles from './FieldHeader.module.scss';

export const FieldHeader = ({
  className,
  ...props
}: JSX.IntrinsicElements['div']): JSX.Element => (
  <div className={joinClassNames(styles.fieldHeader, className)} {...props} />
);
