import React from 'react';

import { config } from './config';
import { ApiProvider } from './providers/ApiProvider';
import { TranslationProvider } from './providers/TranslationsProvider';
import { UserProvider } from './providers/UserProvider';
import 'App.scss';

const apiConfig = {
  API_URI: config.API_URI,
  IDP_URI: config.IDP_URI,
};

export type AppProps = {
  children: React.ReactNode;
};

export const App = ({ children }: AppProps) => {
  return (
    <ApiProvider envConfig={apiConfig}>
      <TranslationProvider>
        <UserProvider>{children}</UserProvider>
      </TranslationProvider>
    </ApiProvider>
  );
};
