/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { joinClassNames } from '@estimateone/frontend-components';
import styles from './FieldDescription.module.scss';

export const FieldDescription = ({
  className,
  ...props
}: JSX.IntrinsicElements['p']): JSX.Element => (
  <p className={joinClassNames(styles.description, className)} {...props} />
);
