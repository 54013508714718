import React from 'react';
import { Form } from 'react-final-form';
import { Button, ButtonVariant } from '@estimateone/frontend-components';
import { Categories } from '@src/components/Categories';
import {
  ContractSize,
  ContractSizeMaxEnumMap,
  ContractSizeMinEnumMap,
} from '@src/components/ContractSize';
import { FieldDescription } from '@src/components/FieldDescription';
import { FieldHeader } from '@src/components/FieldHeader';
import { FindYourABNLink } from '@src/components/FindYourABNLink';
import { FormError } from '@src/components/FormError';
import { WorkforceSize } from '@src/components/WorkforceSize';
import { useTranslator } from '@src/providers/TranslationsProvider';
import { ABN } from 'components/ABN';
import { extractFieldErrors } from '@src/utils';
import {
  useInitialFormValues,
  useSubmitForm,
} from '@src/views/ProfileOnboarding/hooks';
import { UpdateCompanyProfileMutation } from './__generated__/mutations.types';
import {
  ProfileOnboardingFieldNames,
  ProfileOnboardingFieldValues,
} from '@src/components/types';
import styles from './DirectoryOnboarding.module.scss';

type ContractSizeFieldErrors = {
  [Field in ProfileOnboardingFieldNames]?: string[];
};

const validate = (
  values: ProfileOnboardingFieldValues,
): ContractSizeFieldErrors => {
  const errors: ContractSizeFieldErrors = {};

  const contractMin = values[ProfileOnboardingFieldNames.MinContractSize];
  const contractMax = values[ProfileOnboardingFieldNames.MaxContractSize];

  if (
    contractMin?.value &&
    contractMax?.value &&
    ContractSizeMinEnumMap[contractMin.value] >=
      ContractSizeMaxEnumMap[contractMax.value]
  ) {
    errors[ProfileOnboardingFieldNames.MinAndMaxContractSize] = [
      'Min contract value should be less than max contract value',
    ];
  }

  if (contractMin?.value === null && contractMax?.value === null) {
    errors[ProfileOnboardingFieldNames.MinAndMaxContractSize] = [
      'Min or max contract value should be filled in',
    ];
  }

  return errors;
};

export type DirectoryOnboardingProps = {
  onSuccess?: () => void;
  onCancel?: () => void;
};

const buildFieldErrors = (data: UpdateCompanyProfileMutation) => {
  const errorStrategies = [
    (data: UpdateCompanyProfileMutation) => {
      if (
        data?.changeBusinessIdentifier &&
        data.changeBusinessIdentifier.__typename === 'Errors'
      ) {
        const errors = data.changeBusinessIdentifier.errors;

        return {
          [ProfileOnboardingFieldNames.CompanyABN]: errors
            .filter(
              (err) => err?.field === ProfileOnboardingFieldNames.CompanyABN,
            )
            .map((err) => err?.message),
        };
      }
    },
    (data: UpdateCompanyProfileMutation) => {
      if (
        data?.updateContractSize &&
        data.updateContractSize.__typename === 'FieldErrors'
      ) {
        const errors = data.updateContractSize.errors;

        return extractFieldErrors(errors, [
          ProfileOnboardingFieldNames.MaxContractSize,
          ProfileOnboardingFieldNames.MinContractSize,
          ProfileOnboardingFieldNames.MinAndMaxContractSize,
        ]);
      }
    },
  ];

  const errors = errorStrategies
    .map((fn) => fn(data))
    .reduce((fieldErrors, fieldError) => {
      return { ...fieldErrors, ...fieldError };
    }, {});

  return errors;
};

export const DirectoryOnboarding = ({
  onSuccess,
  onCancel,
}: DirectoryOnboardingProps) => {
  const { initialValues, loading: isLoadingValues } = useInitialFormValues();
  const { submit: submitForm, loading, error } = useSubmitForm();
  const translate = useTranslator();

  const onSubmit = async (value: ProfileOnboardingFieldValues) => {
    const { data, errors } = await submitForm(value);

    if (errors) {
      return;
    }

    const fieldErrors = (data && buildFieldErrors(data)) || {};

    if (Object.keys(fieldErrors).length > 0) {
      return fieldErrors;
    }

    onSuccess && onSuccess();
  };

  return (
    <div className={styles.onboardingForm}>
      {error && <FormError />}
      <h1 className={styles.formHeader}>Complete your Company Profile</h1>
      <p className={styles.formDescription}>
        The following fields are required to list your Company Profile in the
        Subcontractor Directory
      </p>
      {isLoadingValues ? (
        <div />
      ) : (
        <Form<ProfileOnboardingFieldValues>
          onSubmit={onSubmit}
          keepDirtyOnReinitialize
          validate={validate}
          initialValues={initialValues}
        >
          {({ handleSubmit, submitErrors, errors }) => (
            <form onSubmit={handleSubmit} className={styles.formFieldsWrapper}>
              <div>
                <FieldHeader>
                  {translate('businessIdentifierFieldHeader')}*
                </FieldHeader>
                <FieldDescription className="mb-8">
                  {translate('businessIdentifierFieldDescription')}
                  <FindYourABNLink />
                </FieldDescription>
                <ABN />
              </div>
              <div>
                <FieldHeader>What size is your workforce?*</FieldHeader>
                <FieldDescription className="mb-8">
                  Adding the size of your workforce helps match your business to
                  the best sized jobs.
                </FieldDescription>
                <WorkforceSize />
              </div>
              <div>
                <FieldHeader>
                  What is your preferred contract size?*
                </FieldHeader>
                <FieldDescription>
                  Don't waste time on unsuitable jobs. Get invited to quote jobs
                  that match your contract value preferences.
                </FieldDescription>
                <ContractSize submitErrors={submitErrors} errors={errors} />
              </div>
              <div>
                <FieldHeader>
                  What type of projects do you prefer working on?*
                </FieldHeader>
                <FieldDescription>
                  {translate('projectCategoryFieldDescription')}
                </FieldDescription>
                <Categories />
              </div>
              <div className={styles.adjacentFields}>
                <Button
                  onClick={onCancel}
                  disabled={loading}
                  variant={ButtonVariant.Secondary}
                  fullWidth
                  type="button"
                >
                  Not Now
                </Button>
                <Button
                  disabled={loading}
                  variant={ButtonVariant.Primary}
                  fullWidth
                  type="submit"
                >
                  Submit
                </Button>
              </div>
            </form>
          )}
        </Form>
      )}
    </div>
  );
};
