/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useField } from 'react-final-form';
import { Select, SelectProps } from '@estimateone/frontend-components';
import { ValueLabelOption } from '@estimateone/frontend-components/dist/src/components/Form/Select/types';
import { FieldWrapper } from '../FieldWrapper';

export type MultiSelectFieldProps = SelectProps<ValueLabelOption, true> & {
  fieldName: string;
  hideErrors?: boolean;
};

export function MultiSelectDropdownField({
  fieldName,
  id,
  className,
  hideErrors = false,
  ...props
}: MultiSelectFieldProps) {
  const {
    input,
    meta: { error, submitError, touched, pristine },
  } = useField(fieldName);

  return (
    <div className={className} data-name={fieldName}>
      <FieldWrapper
        errors={error || submitError}
        showErrors={!hideErrors && (!pristine || touched)}
      >
        <Select id={id} {...props} {...input} isMulti />
      </FieldWrapper>
    </div>
  );
}
